<template>
  <section class="transfer">
    <h2 class="heading">Request Account Statements</h2>
    <p class="sm_text">Select an account</p>
    <el-select
      v-model="form.SourceNuban"
      placeholder="Select Account"
      style="width: 100%"
    >
      <el-option
        v-for="account in userAccounts"
        :key="account.NUBAN"
        :label="account.NUBAN + ' - ' + account.ACCT_TYPE"
        :value="account.NUBAN"
      >
        {{ account.NUBAN }} - {{ account.ACCT_TYPE }}
      </el-option>
    </el-select>
    <small class="error" v-if="submitted && validateNuban"
      >Source Account is required</small
    >

    <section class="filter">
      <div class="block">
        <div class="demonstration">Select Start Date</div>
        <el-date-picker
          v-model="form.startDate"
          type="date"
          placeholder="Pick a Start Date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <br />
        <small class="error" v-if="submitted && validateStartDate"
          >Start Date is required</small
        >
      </div>
      <br />

      <div class="block">
        <div class="demonstration">Select End Date</div>
        <el-date-picker
          v-model="form.endDate"
          type="date"
          placeholder="Pick a End Date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <br />
        <small class="error" v-if="submitted && validateEndDate"
          >End Date is required</small
        >
      </div>
      <br />

      <!-- <el-form :model="form" class="filter__wrapper">
        <div class="filter__options">
          <el-form-item label=" Select Date Range">
            <el-date-picker
              clearable
              v-model="form.date"
              @change="formatDate"
              type="daterange"
              unlink-panels
              range-separator="-"
              start-placeholder="Start date"
              end-placeholder="End date"
              align="center"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <small class="error" v-if="submitted && validateDate"
          >Date Range is required</small
        >
        <el-form-item> </el-form-item>
      </el-form> -->
      <button class="btn btn__red btn__group" @click="requestStatement">
        <loading v-if="loading" />
        <span>Request Statement</span>
      </button>
    </section>
  </section>
</template>

<script>
import dayjs from "dayjs";
let utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

import api from "@/api/api.js";
import { mapState } from "vuex";

export default {
  computed: mapState(["userAccounts", "user"]),
  data() {
    return {
      submitted: false,
      loading: false,
      validateStartDate: false,
      validateEndDate: false,
      validateNuban: false,
      form: {
        startDate: "",
        endDate: "",
        SourceNuban: "",
      },
    };
  },

  methods: {
    requestStatement() {
      this.validateStartDate = false;
      this.validateEndDate = false;
      this.validateNuban = false;
      this.submitted = true;

      if (this.form.SourceNuban == "") {
        this.validateNuban = true;
        return;
      }

      if (this.form.startDate == "") {
        this.validateStartDate = true;
        return;
      }

      if (this.form.endDate == "") {
        this.validateEndDate = true;
        return;
      }

      this.loading = true;

      let payload = {
        Email: this.user.profileInfo.email,
        UserId: this.user.profileInfo.SMID,
        StartDate: this.form.startDate,
        EndDate: this.form.endDate,
        DebitAccount: this.form.SourceNuban,
        Nuban: this.form.SourceNuban,
        Channel: "02",
        IsDebit: false,
      };

      api
        .requestStatement(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.data.responseCode == "00";
          let responseMessage = response.data.dataResponse;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `Statement Request Successfully sent to ${this.user.profileInfo.email}`,
              type: "success",
              duration: 5000,
            });
            setTimeout(() => {
              this.$router.push("/dashboard-home");
            }, 1000);
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    // formatDate() {
    //   let startDate = this.form.date[0];
    //   this.startDate = dayjs(String(startDate)).utc().format();
    //   let endDate = this.form.date[1];
    //   this.endDate = dayjs(String(endDate)).utc().format();
    // },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";

.sm_text {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  padding-left: 1rem;
  color: #000000;
}
</style>
